import './index.css';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';
import Footer from 'components/Footer';
import Section from "components/Section";
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';

import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import yunoTexture from 'assets/yuno.png';
import yunoTexture1 from 'assets/yuno1.png';
import ProjectSummary from 'pages/Home/ProjectSummary';

//const AllProjects = lazy(() => import('components/projects/AllProjectsComponent'));

const disciplines = [
  'Full-stack',
  'UX/UI',
  'VR & AR',
  'AI',
  'Game Dev'
];

const Home = () => {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const allProjects = useRef();
  const news = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();
  const [email, setEmail] = useState("");

  useEffect(() => {
    const revealSections = [intro];

    const sectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const section = entry.target;
            observer.unobserve(section);
            if (visibleSections.includes(section)) return;
            setVisibleSections(prevSections => [...prevSections, section]);
          }
        });
      },
      { rootMargin: '0px 0px -10% 0px' }
    );

    const indicatorObserver = new IntersectionObserver(
      ([entry]) => {
        setScrollIndicatorHidden(!entry.isIntersecting);
      },
      { rootMargin: '-100% 0px 0px 0px' }
    );

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);


  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [allProjects];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;
      scrollObserver = new IntersectionObserver(
        (entries, observer) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            scrollTimeout = setTimeout(
              () => {
                element.current.focus();
              },
              prefersReducedMotion ? 0 : 400
            );
            observer.unobserve(entry.target);
          }
        },
        { rootMargin: '-20% 0px -20% 0px' }
      );

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <div>
      <div className="home">
        <Helmet>
          <title>Pick</title>
          <meta
            name="Pick"
            content="Présentation de l'application Pick"
          />
          <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
          <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
        </Helmet>
        <ProjectSummary
          id="intro"
          alternate
          sectionRef={intro}
          visible={visibleSections.includes(intro.current)}
          index={1}
          store={true}
          title="Trouvez vos meilleures photos"
          description="Trouvez vos meilleures photos et aidez les autres en votant pour leurs photos avec Pick, un nouveau réseau social dédié à l'entraide !"
          buttonText="App store"
          buttonLink="https://apps.apple.com/us/app/pick/id6450153921"
          icon="apple"
          button1Text="Google Play"
          button1Link="https://play.google.com/store/apps/details?id=com.firefly.pick"
          icon1="google"
          model={{
            type: 'phone',
            alt: 'App login screen',
            textures: [
              {
                src: yunoTexture,
                srcSet: `${yunoTexture} 254w, ${yunoTexture} 508w`,
                placeholder: yunoTexture,
              },
              {
                src: yunoTexture1,
                srcSet: `${yunoTexture1} 254w, ${yunoTexture1} 508w`,
                placeholder: yunoTexture1,
              },
            ],
          }}
        />
        <Footer />
      </div >

    </div >

  );
};

export default Home;
